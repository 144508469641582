import UserApi from '@/apis/userApi';

import { clearToken, setToken } from '@/utils/auth';
import { defineStore } from 'pinia';
import { useWSStore } from '../webscoket';
import { UserState } from './types';
import { JSEncrypt } from 'jsencrypt';

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    id: undefined,
    username: undefined,
    phone: undefined,
    token: undefined,
    companyName: undefined,
    address: undefined,
    yingyeAddress: undefined,
    complete: undefined,
    expireTime: 0,
    noReadMessageCount: 0,
    sendMessageCount: 0,
    unitType2: ''
  }),
  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    },
    isLogin(state: UserState): boolean {
      if (state.token && state.token.length > 0 && state.expireTime && state.expireTime > new Date().getTime() / 1000) {
        return true;
      }
      this.resetInfo();
      return false;
    },
    webSocket() {
      const ws = useWSStore();
      return ws;
    }
    // sharedWorker() {
    //   const sharedWorkerStore = useSharedWorkerStore();
    //   return sharedWorkerStore;
    // }
  },
  actions: {
    // 设置用户的信息
    setInfo(userInfo: Partial<UserState>) {
      // 过期时间24小时
      userInfo.expireTime = Date.now() / 1000 + 23 * 60 * 60;
      this.$patch(userInfo);
    },
    // 设置完成
    setComplete(complete: boolean) {
      this.complete = complete;
    },
    // 重置用户信息
    resetInfo() {
      this.$reset();
    },
    // 异步登录并存储token
    async login(loginForm: { phone: string; password?: string; smsCode?: string }) {

      //加密密码
      let enPassWord = this.encryptData(loginForm.password)

      const result = await UserApi.login({phone: loginForm.phone, password: enPassWord, smsCode: loginForm.smsCode });
      this.setInfo(result);
      const token = result?.token;
      if (token) {
        setToken(token);
      }
      this.webSocket.connectWs();
      // this.sharedWorker.connectWs();
      // this.sharedWorker.sendMessage({ type: 'login', result });
      return result;
    },
    // Logout
    async logout() {
      this.resetInfo();
      clearToken();
      this.noReadMessageCount = 0;
      this.webSocket.closeWs();
      // this.sharedWorker.closeWs();
      // this.sharedWorker.sendMessage({ type: 'logout' });
    },
    getNoReadMessageCount() {
      this.webSocket.sendMessage({ type: 'messageCount', flag: true, message: '获取未读消息数量' });
      // this.sharedWorker.sendWsMessage({ type: 'messageCount', flag: true, message: '获取未读消息数量' });
    },
    addSendMessageCount() {
      this.sendMessageCount++;
    },
    clearSendMessageCount() {
      this.sendMessageCount = 0;
    },
    encryptData(data:string) {

      // 假设你有一个公钥
      const publicKeyPem = `MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIr9LPr85ktZBDkuOLsedrybfQEalSz+P5Cy+QsFkMf2zFoc3WiwMAqduA07wSntIXud7kqLdszn6juhQuHMEW0CAwEAAQ==`;

      let enc = new JSEncrypt({default_key_size: "2048"});

      enc.setPublicKey(publicKeyPem)

      return enc.encrypt(data);


    }
  },
  // 持久化
  persist: {
    key: `${import.meta.env.VITE_APP_STORE_PREFIX}_user`,
    storage: localStorage
  }
});
